import React, { useContext, useState, useEffect } from "react";
import { auth } from "./firebase";
import Debug from "debug";
// import { GoogleAuthProvider } from "firebase/auth";
import { onAuthStateChanged, FacebookAuthProvider } from "firebase/auth";

const debug = Debug("AuthProvider");

const AuthContext = React.createContext({
  currentUser: null,
  userLoggedIn: null,
  isEmailUser: null,
  claims: null
  // setCurrentUser,
  // getIdToken: async (_force) => null
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ appId, children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [claims, setClaims] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false);
  //const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [, setIsFacebookUser] = useState(false);
  const [loading, setLoading] = useState(true);

  /**
   * @param {{ providerData: any[]; getIdTokenResult: () => any; }} user
   */
  async function initUser(user) {
    if (user) {
      setCurrentUser(user);
      // check if provider is email and password login
      const isEmail = user.providerData.some(
        (provider) => provider.providerId === "password"
      );
      setIsEmailUser(isEmail);
      const idTokenResult = await user.getIdTokenResult();
      setClaims(idTokenResult.claims);

      // check if the auth provider is google or not
      // const isGoogle = user.providerData.some(
      //   (provider) => provider.providerId === GoogleAuthProvider.PROVIDER_ID
      // );
      // setIsGoogleUser(isGoogle);

      // check if the auth provider is facebok or not
      const isFacebook = user.providerData.some(
        (provider) => provider.providerId === FacebookAuthProvider.PROVIDER_ID
      )
      setIsFacebookUser(isFacebook);
      const { claims } = idTokenResult;
      debug('initializeUser:', { claims }, `Exp: ${new Date(claims.exp * 1000).toTimeString()}`);

      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }

    setLoading(false);
  }

  // This MAY be needed ****
  // const initializeUser = React.useCallback(initUser, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initUser);
    return unsubscribe;
  }, []);

  const value = {
    userLoggedIn,
    isEmailUser,
    currentUser,
    //setCurrentUser,
    //isGoogleUser,
    claims
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}