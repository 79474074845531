import realmJson from "../realm.json";
import firebaseEnvs from "../firebaseEnvs.json";
const { REACT_APP_BACKEND } = process.env; 

export default function Config() {
    return RealmConfig();
}

export function RealmConfig() {
    let config = realmJson.production;
    if (REACT_APP_BACKEND === 'dev') {
        config = realmJson.development;
    }
    // console.log('Config:', config, process.env)
    return config 
}

export function FirebaseConfig() {
    let config = firebaseEnvs.production;
    if (REACT_APP_BACKEND === 'dev') {
        config = firebaseEnvs.development;
    }
    // console.log('Config:', config, process.env)
    return config 
}

