import React from "react";
import {
  Container,
  Typography,
  List,
  LinearProgress
} from "@mui/material";
import { useShowLoader } from "../../../hooks/util-hooks";
import { OrdersTable } from "./OrdersTable";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useAPI } from "../../../hooks/useAPI";

export function OrdersPage({ currentUser }) {
  const { accessToken } = currentUser;
  const { data: orders, loading } = useAPI("me/orders", { accessToken });

  const showLoader = useShowLoader(loading, 200);
  useDocumentTitle("My Orders");

  return (
    <Container disableGutters={true} maxWidth="sm">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
        <div style={{ marginTop: 5 }}>
          <div>
            {/* <UnReadChatBanner currentUser={currentUser} /> */}
            <Typography style={{ marginLeft: 10 }} variant="h6">
              {`${orders.length} order${orders.length === 1 ? "" : "s"
                }`}
            </Typography>
          </div>
          <List style={{ width: "100%" }}>
            <OrdersTable currentUser={currentUser} items={orders} store={undefined} />
          </List>
        </div>
      )}
    </Container>
  );
}
