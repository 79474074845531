import React from "react";
import Debug from "debug";
import {
  Container, LinearProgress, ImageList, ImageListItem, ImageListItemBar,
  Select, InputLabel, MenuItem, FormControl, Button, Divider, Alert
} from "@mui/material";
import { useShowLoader } from "../../hooks/util-hooks";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useHttpEndpoint } from "../../hooks/useHttpEndpoint";
import ItemImage from "../controls/ItemImage";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { ErrorPage } from "./ErrorPage";
const debug = Debug("CategoryPage");

export function CategoryPage({ store }) {
  const { category } = useParams();
  const [ searchParams, setSearchParams ] = useSearchParams();
  debug('searchParams:', searchParams);
  let catSearch = category.toLowerCase();
  if (category.toLowerCase() === "new") {
    catSearch = "$ALL";
  }

  const [size, setSize] = React.useState(sessionStorage.getItem('size'));
  const [skip, setSkip] = React.useState(Number(searchParams.get('skip'))|| 0);
  debug('skip:', skip)
  const sizes = store.options.map((o) => o.size);
  const queryParams = { category: catSearch, limit: 90, storeId: store._id, skip }
  if (size) {
    queryParams.size = size;
  }
  const { loadData, data, loading } = useHttpEndpoint('listings', { queryParams });
  const showLoader = useShowLoader(loading, 200);
  useDocumentTitle(`Category: ${category.charAt(0).toUpperCase() + category.slice(1)}`)
  
  // discount rules
  const categoryObj = store.categories.find(c => c.slug === category.toLowerCase());
  debug('categoryObj', categoryObj);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  function handleChange(e) {
    const size = e.target.value
    setSize(size);
    setSkip(0);
    sessionStorage.setItem('size', size);
  }

  function handleLoadMore() {
    debug(data.count, queryParams);
    setSkip((prev) => {
      const skip = prev + data.count
      setSearchParams({skip});
      return skip;
    });
  }

  if (!loading && !data?.documents) {
    return <ErrorPage error={{message: 'Error fetching data'}} />
    // debug(data.documents.length, data.total);
  }

  return (
    <Container style={{ marginTop: 15 }} maxWidth="sm" className="main-container">
      {loading ? (
        showLoader ? (
          <LinearProgress />
        ) : <div />
      ) : (
        <div>
          <h3>
            Shop <span style={{ textTransform: "capitalize" }}>{category}</span>
            {categoryObj?.discountRate ? <Alert severity="error">{categoryObj.discountRate}% Off for a limited time</Alert>:""}
          </h3>
          <FormControl variant="standard" sx={{ ml: 3, minWidth: 120 }} size="small">
            <InputLabel id="size-select-label">Filter by Size:</InputLabel>
            <Select
              labelId="size-select-label"
              id="size-select"
              value={size || ""}
              label="Size"
              onChange={handleChange}
            >
              <MenuItem value={""}>---</MenuItem>
              {sizes.map((opt, idx) => (
                <MenuItem key={idx} value={opt}>{opt}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <ImageList>
            {data.documents.map((item) => (
              <ImageListItem key={item._id}>
                <Link to={"/items/" + item._id}>
                  <ItemImage url={item.imageUrl} />
                  <ImageListItemBar
                    title={item.title}
                    subtitle={<span>${item.price} size:{item.size} </span>}
                  />
                </Link>
              </ImageListItem>
            ))}
          </ImageList>
          <Divider>
            {data.total !== data.documents.length &&
              <Button onClick={handleLoadMore} variant="outlined"> Load More </Button>
            }
          </Divider>
        </div>)}
    </Container>
  );
}
