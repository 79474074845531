import axios from 'axios';
import { FirebaseConfig } from "../shared/Config";
const { restAPIHost } = FirebaseConfig();

/**
 * @param {{ accessToken: string, getIdToken: (arg0: any) => Promise<string> }} currentUser
 */
export function createAxiosInstance(currentUser) {

  const axiosInstance = axios.create({
    baseURL: restAPIHost,
    timeout: 8000,
    headers: { 
      'Content-Type': 'application/json', 
      Accept: 'application/json', 
      Authorization: `Bearer ${currentUser.accessToken}`
     }
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // console.log("responseInterceptor Error", { error })
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        //if (error) {
        console.log("## intercepted 401 ERR")
        originalRequest._retry = true;

        try {
          if (currentUser) {
            const idToken = await currentUser.getIdToken(true); // Force refresh the token
            originalRequest.headers['Authorization'] = `Bearer ${idToken}`;
            return axios(originalRequest);
          } else {
            console.log("Not logged in")
            // Handle the case where the user is not logged in
          }
        } catch (error) {
          // Handle error during token refresh
          console.error('Error refreshing token:', error);
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}
