import React from "react";
// import Debug from "debug";
import { addItemToArray } from "./util-hooks";
import { useAuth } from "../modules/Auth/AuthProvider";
import { useAPI } from "./useAPI";
// const debug = Debug("hooks:useInvites");

export function useInvites() {
  const [invites, setInvites] = React.useState([]);

  const { currentUser } = useAuth();
  const { accessToken } = currentUser;
  const options = { queryParams: null, cache: true, accessToken }
  const { data, loading, instance } = useAPI('me/invites', options);

  React.useEffect(() => {

    if (!loading) {
      setInvites(data);
    }
  }, [loading, data]);

  const createInvite = async ({ email, kind, store }) => {
    const { _id, name } = store
    const item = {
      _partition: currentUser.id,
      kind,
      status: 'pending',
      store: { _id, name },
      email,
      createdTs: new Date()
    };
    try {
      // await collection.insertOne(item);
      await instance.post('me/invites', item);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn('Error: tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending duplicate request');
      }
      console.error(err);
    }

    setInvites(addItemToArray(item, invites));
  };

  const update = async (id, doc) => {
    doc.updatedTs = new Date()
    try {
      // await collection.updateOne(
      //   { _id: id },
      //   { $set: doc }
      // );
      await instance.put('me/invites', doc);
    } catch (err) {
      if (err.error.match(/^Duplicate key error/)) {
        console.warn('Error: tried to insert same _id multiple times. You may want to debounce the save input or implement an additional loading state to avoid sending duplicate request');
      }
      console.error(err);
    }
  };

  return {
    loading,
    invites,
    createInvite,
    update
  };
}
