import { FirebaseConfig } from "../shared/Config";
import useAxiosWithInterceptor from "./useAxiosWithInterceptor";
const { restAPIHost } = FirebaseConfig();
const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};

/**
 * @param {string} path
 * @param {APIHookOptions} options
 * @returns {APIHookResponse}
 */
export function useAPI(path, { accessToken = "", queryParams = null, useCache = false }) {
    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`
    }

    const url = `${restAPIHost}/${path}`
    const config = { url, headers };
    if (queryParams) {
        const searchParams = new URLSearchParams(queryParams);
        config.url = `${config.url}?${searchParams.toString()}`;
    }

    const { data, loading, error, refetch, instance } = useAxiosWithInterceptor(config, { useCache });
    return { loading, data, error, refetch, instance };
}
