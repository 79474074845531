import Debug from "debug";
import axios from "axios";
import { FirebaseConfig } from "../shared/Config";
const { restAPIHost } = FirebaseConfig();
const debug = Debug("getEndpoint");
const endpointUrl = `${restAPIHost}/api`
const headers = { 'Accept': 'application/json' };

/**
 * @param {string} path 
 * @param {object} queryParams
 * @returns {Promise<object[]>}
 */
export async function getEndpoint(path, queryParams) {
  const config = { url: `${endpointUrl}/${path}`, headers };
  if (queryParams) {
    const searchParams = new URLSearchParams(queryParams);
    config.url = `${config.url}?${searchParams}`;
  }

  return axios(config)
    .then(response => {
      const { data } = response;
      // debug('fetch', { data });
      return data.documents;
    });
}

/** 
 * @param {Object} param
 * @param {string} param.token
 */
export async function getUploadURL({ token }) {
  headers.Authorization = `Bearer ${token}`;
  const config = { url: `${endpointUrl}/uploadURL`, headers };

  return axios(config)
    .then(response => {
      const { data } = response;
      debug('fetch', { data });
      return data;
    });
}

export async function getOnboardURL() {
  const config = { url: `${endpointUrl}/onboardurl`, headers };
  return axios(config)
    .then(response => {
      const { data } = response;
      debug('getOnboardURL: fetch', { data });
      return data;
    })
    .catch(err => {
      console.error('getOnboardURL error:', { err });
    });
}

const STATUS_CACHE = {}
/**
 * @param {string} merchantId
 */
export async function getOnboardStatus(merchantId) {
  const config = { url: `${endpointUrl}/sellerstatus?merchantId=${merchantId}`, headers };
  if (STATUS_CACHE[merchantId]) {
    return Promise.resolve(STATUS_CACHE[merchantId]);
  }
  return axios(config)
    .then(response => {
      const { data } = response;
      STATUS_CACHE[merchantId] = data;
      debug('getEndpoint: fetch', { data });
      return data;
    });
}

/**
 * @param {any} email
 */
export async function userEmailTaken(email) {
  const queryParams = { email }
  return getEndpoint('useremailtaken', queryParams)
}

/**
 * @param {any} itemId
 */
export async function getItem(itemId) {
  const queryParams = { itemId }
  return getEndpoint('getListing', queryParams)
}

/**
 * 
 * @param {string} text 
 * @param {string} storeId 
 */
export async function SearchItems(text, storeId) {
  return getEndpoint('search', { text, storeId })
}