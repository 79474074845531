import { makeUseAxios } from "axios-hooks";
import { useAuth } from '../modules/Auth/AuthProvider';
import { createAxiosInstance } from '../shared/axiosInstance';

/**
 * @param {AxiosRequestConfig} config
 * @returns { APIHookResponse }
 */
function useAxiosWithInterceptor(config, { useCache }) {
  const { currentUser } = useAuth();
  const axiosInstance = createAxiosInstance(currentUser);
  const useAxios = makeUseAxios({ axios: axiosInstance });
  const [{ data, loading, error }, refetch] = useAxios(config, { useCache })
  return { data, loading, error, refetch, instance: axiosInstance };
};

export default useAxiosWithInterceptor;