/// <reference path="../typedefs.js" />
import React from "react";
import Debug from "debug";
import { useAuth } from "../modules/Auth/AuthProvider";
import { useAPI } from "./useAPI";
const debug = Debug("useConsignors");

export function useConsignors() {
  /** @type {useState<Consignor[]>} */
  const [items, setItems] = React.useState([]);

  const { currentUser } = useAuth();
  const { accessToken } = currentUser;

  const { data, loading, instance } = useAPI('me/consignors', { accessToken });

  React.useEffect(() => {
    if (!loading) {
      setItems(data);
    }
  }, [data, loading]);

  const addConsignor = async ({ name, venmo }) => {
    const item = {};
    // item.storeId = String(store._id);
    item.name = name;
    item.venmo = venmo;
    item.created_ts = new Date();
    try {
      await instance.post('me/consignors', { ...item })
      items.push(item);
      setItems(items);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteConsignor = async (item) => {
    const updatedItems = items.filter((i) => i._id !== item._id);
    await instance.delete(`me/consignors/${item._id}`);
    setItems(updatedItems);
    debug('deleteConsignor:', { item }, { updatedItems });
  };

  return {
    loading,
    items,
    addConsignor,
    deleteConsignor
  };
}
