export const toggleBoolean = (prev) => !prev;
export const CF_IMAGES_ACCOUNTID = "JsOwOQEQsyMK6pDicrj7_w";
export const regionList = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'];
export const searchTerms = "Athleta,American Eagle,Adidas,Abercrombie,Boden,Crocs,Crewcuts,DSG,GAP,Hunter,Hollister,Jcrew,Janie,Lululemon,Nike,Patagonia,Tea,UGG,Vineyard,Zara";

const imageProxyURL = "https://images.swag-shops.com/image-proxy"

let sleepSetTimeout_ctrl;
export function sleep(ms) {
    clearInterval(sleepSetTimeout_ctrl);
    return new Promise(resolve => sleepSetTimeout_ctrl = setTimeout(resolve, ms));
}

export const trackEvent = ({ action, params = {} }) => {
  // @ts-ignore
  if (window.gtag) {
     // @ts-ignore
    window.gtag('event', action, params);
  }
}

export function filterOptions(options, key) {
  return options.map((i) => {
    if (i[key]) {
      return { key: i[key] };
    }
    return false;
  }).filter(a => a);
}

const isValidArrayIndex = (arr, idx) => {
  return !(idx < 0 || idx >= arr.length);
};

export function addValueAtIndex(arr, idx, value) {
  if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
    throw new Error(`Cannot add value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr, idx, newValue) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot replace value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(arr, idx, updater) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot update value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr, idx) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot remove value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export const isSameTodo = (todo1, todo2) =>
  String(todo1?._id) === String(todo2?._id);

export const getTodoIndex = (todos, todo) => {
  const idx = todos.findIndex((t) => isSameTodo(t, todo));
  return idx >= 0 ? idx : null;
};

export const isSameItem = (a, b) =>
  String(a?._id) === String(b?._id);

export const getItemIndex = (items, item) => {
  const idx = items.findIndex((t) => isSameItem(t, item));
  return idx >= 0 ? idx : null;
};

export function avatarImageURL(imageUrl) {
  if (imageUrl?.includes('imagedelivery.net')) {
    const newImageUrl = imageUrl.replace(`https://imagedelivery.net/${CF_IMAGES_ACCOUNTID}`, imageProxyURL);
    return `${newImageUrl}/xsmall`;
  }
  const resize = `image/upload/c_pad,b_lightgrey,w_128,h_128/`;
  return imageUrl?.split('image/upload/').join(resize);
}

/**
 * @param {string} imageUrl 
 * @param {object} obj
 * @param {boolean} [obj.large]
 * @param {object} [obj.imageSize]
 * @param {string} [obj.variantOverride]
 */
export function itemImageURL(imageUrl, { imageSize, large, variantOverride } = {}) {
  let dimensions = large ? { width: 800, height: 900 } : { width: 600, height: 600 };

  // CF images
  if (imageUrl?.includes('imagedelivery.net')) {
    let variant = large ? 'itemlarge' : 'itemsmall';
    variant = variantOverride ? variantOverride : variant;
    const newImageUrl= imageUrl.replace(`https://imagedelivery.net/${CF_IMAGES_ACCOUNTID}`, imageProxyURL)
    return `${newImageUrl}/${variant}`;
  }

  // Cloudinary
  if (imageSize?.width && imageSize?.height) {
    dimensions = imageSize;
  }
  const resize = `image/upload/c_pad,b_lightgrey,w_${dimensions.width},h_${dimensions.height}/`;
  return imageUrl?.split('image/upload/').join(resize) || '';
}

export function itemImageUrls(item = {}) {
  const imageUrls = [];
  if (item.imageUrl) {
    imageUrls.push(item.imageUrl);
  }
  if (item.imageUrl2) {
    imageUrls.push(item.imageUrl2);
  }
  if (item.imageUrl3) {
    imageUrls.push(item.imageUrl3);
  }
  if (item.imageUrl4) {
    imageUrls.push(item.imageUrl4);
  }
  if (item.imageUrl5) {
    imageUrls.push(item.imageUrl5);
  }
  if (item.imageUrl6) {
    imageUrls.push(item.imageUrl6);
  }
  return imageUrls
}

export const itemConditions = [
  { code: "A", name: "New With Tags" },
  { code: "A2", name: "New In Box" },
  { code: "A3", name: "New without tags" },
  { code: "B", name: "Excellent Used" },
  { code: "C", name: "Very Good Used" },
  { code: "D", name: "Good Used" }
];

export function getReportMonths(number) {
  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  function subtractMonths(date, months) {
    date.setMonth(date.getMonth() - months);
    return date;
  }
  const today = new Date();
  const reportMonths = [`${today.getFullYear()}-${months[today.getMonth()]}`];
  for (let index = 0; index < number; index++) {
    const date = subtractMonths(today, 1)
    reportMonths.push(`${date.getFullYear()}-${months[date.getMonth()]}`);
  }
  return reportMonths;
}

/**
 * @param {string} token 
 */
export function isTokenExpired(token) {
  if (!token) {
    return false;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const { exp } = JSON.parse(jsonPayload);
  const expired = Date.now() >= exp * 1000
  return expired
}