import React from "react";
// import { BSON } from "realm-web";
import { useAuth } from "../modules/Auth/AuthProvider";
// import { useRestAPI } from "./useRestAPI";
import { useAPI } from "./useAPI";
// import { useCollection } from "./useCollection";
// import { useRealmApp } from "../components/RealmApp";
// import Config from "../shared/Config";

// const { dataSourceName } = Config();

export function useDeliveryOptions() {
  // const realmApp = useRealmApp();
  const [items, setItems] = React.useState([]);
  // const [loading, setLoading] = React.useState(true);

  const { currentUser } = useAuth();
  const { accessToken } = currentUser;
  // const options = { queryParams: null, cache: true, accessToken }
  const { data, loading, instance } = useAPI('me/deliveryoptions', { accessToken });
  // console.log('ERR:', error);

  // const STORAGE_KEY = `${currentUser.id}_deliveryoptions`;
  // Get a client object for the todo task collection
  // const collection = useCollection({
  //   cluster: dataSourceName,
  //   db: "ecommdb",
  //   collection: "deliveryoptions",
  // });

  React.useEffect(() => {
    // if (sessionStorage.getItem(STORAGE_KEY)) {
    //   setItems(JSON.parse(sessionStorage.getItem(STORAGE_KEY)));
    //   // setLoading(false);
    //   return
    // }
    // collection.find().then((res) => {
    //   // console.log('useDeliveryOptions.js fetch:mongo', {res})
    //   setItems(res);
    //   //sessionStorage.setItem(STORAGE_KEY, JSON.stringify(res));
    //   setLoading(false);
    // });

    // endpoint.get('/me/deliveryoptions').then((res) => {
    //   console.log('res >>', res.data);
    if (!loading) {
      setItems(data);
    }

    //   // sessionStorage.setItem(STORAGE_KEY, JSON.stringify(res.data));
    //   setLoading(false);
    // })
  }, [data, loading]);

  const addDeliveryOption = async ({ name, value }) => {
    const item = {};
    // item.storeId = String(store._id);
    item.name = name;
    item.value = value;
    // item.updated_ts = new Date();
    try {
      // await collection.insertOne(item);
      await instance.post('me/deliveryoptions', { ...item })
      items.push(item);
      setItems(items);
      // sessionStorage.removeItem(STORAGE_KEY);
    } catch (err) {
      console.error(err);
    }
  };


  const deleteDeliveryOption = async (item) => {
    const updatedItems = items.filter((i) => i._id !== item._id);
    setItems(updatedItems);
    // await collection.deleteOne({ _id: item._id });
    await instance.delete(`me/deliveryoptions/${item._id}`);
  };

  return {
    loading,
    items,
    addDeliveryOption,
    deleteDeliveryOption
  };
}
