import { useAPI } from "./useAPI";
import { useAuth } from "../modules/Auth/AuthProvider";

export function useListings({ store, activeListings, category = '' }) {
  const { currentUser } = useAuth();
  const { accessToken } = currentUser;
  const options = { queryParams: { category, activeListings, store }, cache: true, accessToken };
  const { data: listings, loading, refetch, instance } = useAPI('me/listings', options);

  const insertListing = async (draftListing) => {
    if (draftListing.imageUrl) {
      draftListing._partition = currentUser.claims.owner_id;
      try {
        await instance.post('me/listing', draftListing);
        refetch();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const updateListing = async (listing) => {
    // console.log({ listing })
    try {
      await instance.put("me/listing", { listing });
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  return {
    loading,
    listings,
    insertListing,
    updateListing
  };
}
