import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Card, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import { useErrorAlert } from "../../hooks/useErrorAlert";
import { handleAuthenticationError } from "../../modules/Auth/handleAuthenticationError";
import { toggleBoolean } from "../../shared/helpers";

export function EmailRegisterForm({ realmApp, setShowLogin }) {
  const noErrors = {
    email: null,
    password: null,
    other: null,
  };
  const [error, setError] = React.useState(noErrors);
  const clearErrors = () => setError(noErrors);
  const [showConfirm, setConfirm] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const toggleShowPassword = () => setShowPassword(toggleBoolean);
  const NonAuthErrorAlert = useErrorAlert({
    error: error.other,
    hideAfterMs: 9000,
    clearError: () => {
      setError((prevError) => ({ ...prevError, other: null }));
    },
  });

  const onFormSubmit = async ({ email, password }) => {
    const lowerEmail = email.toLowerCase();
    clearErrors();
    try {
      await realmApp.emailPasswordAuth.registerUser({ email: lowerEmail, password });
      setConfirm(true);
    } catch (err) {
      handleAuthenticationError(err, setError);
    }
  };

  return (
    <Card className="auth-card" variant="outlined">
      {showConfirm ? <div>
        <Typography component="h2" variant="h5" gutterBottom>
          Check your email for confirmation link.
        </Typography>
        <Button sx={{mt:4, ml:4}} variant="contained" color="secondary" onClick={() => setShowLogin(true)}>
          Login
        </Button>

      </div> :
        <form
          className="auth-form"
          onSubmit={(e) => {
            e.preventDefault();
            // @ts-ignore
            const formData = new FormData(e.target);
            // @ts-ignore
            const { email, password } = Object.fromEntries(formData.entries());
            onFormSubmit({ email, password });
          }}
        >
          <Typography variant="h5" gutterBottom>
            Sign-up for an account
          </Typography>
          <NonAuthErrorAlert />
          <TextField
            id="input-email"
            name="email"
            label="Email Address"
            variant="outlined"
            autoComplete="username"
            error={Boolean(error.email)}
            helperText={error.email ?? ""}
          />
          <TextField
            id="input-password"
            type={showPassword ? "text" : "password"}
            name="password"
            label="Password"
            variant="outlined"
            error={Boolean(error.password)}
            helperText={error.password ?? ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleShowPassword}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button type="submit" variant="contained" color="primary">
            Create Account
          </Button>
        </form>}
    </Card>
  )
}