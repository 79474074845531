
import { useAuth } from "../modules/Auth/AuthProvider";
import { createAxiosInstance } from "../shared/axiosInstance";


export function useListing() {
  const { currentUser } = useAuth();
  const axiosInstance = createAxiosInstance(currentUser);

  /**
   * @param {Listing} draftItem 
   */
  const insertListing = async (draftItem) => {
    if (draftItem.imageUrl) {
      draftItem._partition = currentUser.id;
      try {
        await axiosInstance.post('/me/listing', draftItem);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const updateListingPart = async (id, doc) => {
    try {
      console.log('updateListing:pre', {doc}, id);
      // console.log('updateListing:', res);
      await axiosInstance.put(`/me/listing/${id}`, doc);
    } catch (err) {
      console.error(err);
    }
  };

  return {
    insertListing,
    updateListingPart,
  };
}
