import { Container } from "@mui/material";
import { useAPI } from "../../../hooks/useAPI";

export function TestPage({store}) {
    const { data, loading, instance } = useAPI('api/401', { accessToken: "zxcvb"});
    if (!loading) {
        console.log("TEST", {data, loading, instance});
    }

    return (
        <Container style={{ marginTop: 15 }} maxWidth="sm" className="main-container">
            <h3>TEST PAGE</h3>
            <h4>{store.name}</h4>
        </Container>
    )
}