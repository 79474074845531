import React from "react";
import { Avatar, Button, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, LinearProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { ChatBubble, CloseRounded } from "@mui/icons-material";
import { useAxiosEndpoint } from "../../hooks/useAxiosEndpoint";
import Conversation from "../../modules/Chat/Conversation";
import { BSON } from "realm-web";
// import { useRealmApp } from "../RealmApp";
import { SingleImageList } from "../lists/SingleImageList";
import { avatarImageURL } from "../../shared/helpers";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useAuth } from "../../modules/Auth/AuthProvider";

export function SellerPage({ store }) {
  const { userId } = useParams();
  const queryParams = { userId };
  const q2 = { sellerId: userId, storeId: store._id };
  const { currentUser } = useAuth();
  const { data, loading } = useAxiosEndpoint('seller', { queryParams });
  const { data: data2, loading: loading2 } = useAxiosEndpoint('sellerlistings', { queryParams: q2, useCache:true });
  // const realmApp = useRealmApp();
  const [open, setOpen] = React.useState(false);
  const [chat, setChat] = React.useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dialogTitleStyle = { minWidth: 320 }
  if (!fullScreen) {
    dialogTitleStyle.minWidth = 520
  }

  function handleClose(value) {
    setOpen(false);
  }

  useDocumentTitle('Seller Page');

  if (loading) return (<LinearProgress />)

  return (
    <Container style={{ marginTop: 15 }} maxWidth="sm" className="main-container">
      <Typography sx={{ mb: 2 }} variant="h5">Chat with Seller</Typography>
      {data.documents &&
        <>
          <Typography sx={{ mb: 2 }} variant="h6">{data.documents[0].fullname}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Avatar src={avatarImageURL(data.documents[0].imageUrl)} sx={{ width: 128, height: 128, m: 1 }} />
            </Grid>
            <Grid item xs={7}>
              <Typography>
                Hi, my name is {data.documents[0].fullname} and I am a seller for {store.name}
              </Typography>
            </Grid>
          </Grid>
          <Button
            disabled={!currentUser}
            onClick={() => {
              const message = {
                users: [data.documents[0].id],
                usersInfo: [
                  { id: currentUser.id, name: currentUser.customData?.fullname },
                  { id: data.documents[0].id, name: data.documents[0].fullname }
                ],
                sender: currentUser.id,
                recipient: data.documents[0].id,
              }
              setChat({ _id: new BSON.ObjectID().toString(), message })
              setOpen(true);
            }}
            fullWidth={true}
            variant="contained" endIcon={<ChatBubble />}>Chat with Seller</Button>
          <Divider sx={{ my: 2 }}></Divider>
          {!loading && data2?.documents?.length ?
            <Typography variant="h6">Recent Listings</Typography>
            : ''
          }
          {!loading2 &&
            <SingleImageList items={data2.documents} />
          }
          <Typography variant="caption">Merchant id: {data.documents[0].merchantId}</Typography>
          <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
            <DialogTitle sx={dialogTitleStyle}>
              <IconButton style={{ float: 'right' }} onClick={() => setOpen(false)}>
                <CloseRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {chat &&
                <Conversation chat={chat} isDialog={open} />
              }
            </DialogContent>
          </Dialog>
        </>
      }
    </Container>
  );
}
