import useAxios from "axios-hooks";
import { FirebaseConfig } from "../shared/Config";
const { restAPIHost } = FirebaseConfig();
const endpointUrl = `${restAPIHost}/api`
const headers = {'Accept': 'application/json'};

export function useAxiosEndpoint(path, { queryParams, useCache = false }) {
    const config = { url: `${endpointUrl}/${path}`, headers };
    if (queryParams) {
        const searchParams = new URLSearchParams(queryParams);
        config.url = `${config.url}?${searchParams.toString()}`;
    }
    const [{ data, loading, error }, refetch ] = useAxios(config, {useCache})
    return { loading, data, error, refetch };
}
