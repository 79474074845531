/// <reference path="../typedefs.js" />
import React from "react";
import axios from "axios";
import Debug from "debug";
import { FirebaseConfig } from "../shared/Config";
const { restAPIHost } = FirebaseConfig();
const debug = Debug("hooks:useStoreSettings");
const endpointUrl = `${restAPIHost}/api/storesettings`
const STORAGE_KEY = "store";
const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

/**
 * @param {boolean} [forceRefresh] 
 * @returns {{loading:boolean, store:Store}}
 */
export function useStoreSettings(forceRefresh) {
  // get SSR embedded json
  const ssrElement = document.getElementById('__SSR_DATA__')
  /** @type {Store} */
  let ssrJson = { _id:'', id:'', name:'', isConsignment: false, updated_ts: new Date()}
  try {
    ssrJson = JSON.parse(ssrElement.textContent)
  } catch (ex) {}
  const { host } = window.location;
  /** @type {useState<Store>} */
  const [store, setStore] = React.useState(ssrJson);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (store.name) {
      setLoading(false);
      return
    }
    const config = { url: endpointUrl, headers};
    if (host) {
      config.url = `${endpointUrl}`; // ?host=${host}
    }
    if (forceRefresh) {
      sessionStorage.removeItem(STORAGE_KEY);
    }
    if (sessionStorage.getItem(STORAGE_KEY)) {
      setStore(JSON.parse(sessionStorage.getItem(STORAGE_KEY)));
      setLoading(false);
      return
    }
    axios(config)
        .then(response => {
          const { data } = response;
          if (data) {
            // const store = data.documents[0];
            const store = data;
            debug('fetch', { store });
            setStore(store);
            sessionStorage.setItem('store', JSON.stringify(store));
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch(err => {}); 
  }, [host, forceRefresh, store]);

  return {
    loading,
    store,
  };
}
