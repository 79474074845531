import React from "react";
import { Box, Button, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { getReportMonths } from "../../../shared/helpers";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { ArrowLeft, Print } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useAuth } from "../../../modules/Auth/AuthProvider";
import { useAPI } from "../../../hooks/useAPI";


export function PayoutReport() {
    const reportMonths = getReportMonths(13);
    const [monthYear, setMonthYear] = React.useState(reportMonths[0]);
    const { currentUser } = useAuth();
    const { accessToken } = currentUser;
    const { data, loading, error } = useAPI('me/payoutreport', { queryParams: { monthYear }, accessToken });

    useDocumentTitle('SellerReport');

    function handleChange(e) {
        // console.log(e);
        setMonthYear(e.target.value);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ m: 1 }}>
                {error ? <>Error: {error.message}. Refresh page</> : ""}
                {(!loading && !error) ?
                    <TableContainer sx={{m:3}}>
                        <Typography variant="h6">Monthly Consignor Payout Report</Typography>
                        <Button size="small" sx={{mb:2}} startIcon={<ArrowLeft />} component={Link} variant={"outlined"} to={"/reports/seller"}> Back</Button>
                        <InputLabel id="month-select-label">Select Month:</InputLabel>
                        <Select
                            labelId="month-select-label"
                            size="small"
                            id="month-select"
                            value={monthYear}
                            label="Month"
                            onChange={handleChange}
                        >
                            {reportMonths.map((m, idx) => (
                                <MenuItem key={idx} value={m}>{m}</MenuItem>
                            ))}
                        </Select>
                        <Button startIcon={<Print />} sx={{ml:2}} variant={"outlined"} onClick={()=> { 
                            const divToPrint=document.getElementsByTagName("table")[0];
                            const newWin = window.open("");
                            newWin.document.write(divToPrint.outerHTML);
                            newWin.print();
                            newWin.close();
                         }}
                        >Print</Button>
                        <Table sx={{ minWidth: 310, pageBreakInside:'auto' }} size="small" aria-label="seller orders">
                            <TableHead sx={{display:'table-header-group'}}>
                                <TableRow>
                                    <TableCell>Consignor</TableCell>
                                    <TableCell>40% Due</TableCell>
                                    <TableCell>Sales Total</TableCell>
                                    <TableCell>Sales</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.documents.map((i, idx) => (
                                    <TableRow hover tabIndex={-1} key={idx} sx={{ pageBreakAfter:'auto', pageBreakInside:'avoid', '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell>{i.group_series_0}</TableCell>
                                        <TableCell>${i.value ? i.value.toFixed(2) : 0} </TableCell>
                                        <TableCell>${i.value_series_1 ? i.value_series_1.toFixed(0) : 0}</TableCell>
                                        <TableCell>{i.value_series_0} ({i.group.year + "-" + i.group.month})</TableCell>
                                    </TableRow>))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : ""}
            </Paper>
        </Box>)
}
