import React from "react";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft } from "@mui/icons-material";
import { useAuth } from "../../../modules/Auth/AuthProvider";
import { useAPI } from "../../../hooks/useAPI";

export function SellerMonthReport() {
    const { yearMonth } = useParams();
    const { currentUser } = useAuth();
    const { accessToken } = currentUser;
    const { data, loading } = useAPI('me/salestotals', { queryParams: { yearMonth }, accessToken });

    useDocumentTitle(`SellerMonthlyReport (${yearMonth})`);

    function sortDateGroup(a, b) {
        return `${b.group.date}`.localeCompare(`${a.group.date}`)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ m: 1 }}>
                {!loading ?
                    <TableContainer sx={{m:3}}>
                        <Typography variant="h6"> Seller Reports for {yearMonth}</Typography>
                        <Button size="small" sx={{mb:2}} startIcon={<ArrowLeft />} component={Link} variant={"outlined"} to={"/reports/seller"}> Back</Button>
                        <Table sx={{ maxWidth: 380, pageBreakInside:'auto' }} size="small" aria-label="seller orders">
                            <TableHead sx={{display:'table-header-group'}}>
                                <TableRow>
                                    <TableCell>Month</TableCell>
                                    <TableCell>Revenue</TableCell>
                                    <TableCell>Sales</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.sort(sortDateGroup).map((i, idx) => (
                                    <TableRow hover tabIndex={-1} key={idx} sx={{'&:last-child td, &:last-child th': { border: 0 } }}>
                                         <TableCell>{i.group.date}</TableCell>
                                        <TableCell>${i.sum ? i.sum.toFixed(2) : 0} </TableCell>
                                        <TableCell>{i.count}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : ""}
            </Paper>
        </Box>)
}
