import Debug from "debug";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material";
// import { pink } from '@mui/material/colors'; // color example

import { useStoreSettings } from "../hooks/useStoreSettings";
// import { useRealmApp } from "./RealmApp";
import React from "react";
import { useAuth } from "../modules/Auth/AuthProvider";

const debug = Debug("Theme")

const defaultThemeConfig = {
  palette: {
    // mode:'light',
    primary: { main: '#000000' },
    secondary: { main: '#EEEEEE' },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    }
  }
};

export function ThemeProvider({ children }) {
  // const { currentUser } = useRealmApp();
  const { currentUser } = useAuth();
  const { store, loading } = useStoreSettings(currentUser); // use true to force refresh
  const _theme = createTheme(defaultThemeConfig);
  const [theme, setTheme] = React.useState(_theme);

  React.useEffect(() => {
    let themeConfig = defaultThemeConfig;
    if (!loading && store.theme) {
      debug('store.theme:', store.theme);
      themeConfig.palette.primary.main = store.theme.split(',')[0];
      themeConfig.palette.secondary.main = store.theme.split(',')[1];
      try {
        setTheme(createTheme(themeConfig));
      } catch (ex) {
        console.error(ex);
      }
    }
  }, [loading, store.theme]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
