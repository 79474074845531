import { auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

/**
 * @param {string} email
 * @param {string} password
 */
export async function doCreateUserWithEmailAndPassword(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
};

/**
 * @param {string} email
 * @param {string} password
 */
export function doSignInWithEmailAndPassword(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
};

export async function doSignInWithGoogle() {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;
  console.log('auth.js', {user})
  // add user to firestore
};

export function doSignOut() {
  return auth.signOut();
};

/**
 * @param {string} email
 */
export function doPasswordReset(email) {
  return sendPasswordResetEmail(auth, email);
};

/**
 * @param {string} password
 */
export function doPasswordChange(password) {
  return updatePassword(auth.currentUser, password);
};

export function doSendEmailVerification() {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};