import { AxiosError } from "axios";
import { FirebaseError } from "firebase/app";
// import * as Realm from "realm-web";

export function handleAuthenticationError(err, setError) {
    const handleUnknownError = () => {
      setError((prevError) => ({
        ...prevError,
        other: `Unknown error occured. ${err}`,
      }));
      console.warn(`Unknown error occured: ${err}`);
      console.error(err);
    };

    if (err instanceof FirebaseError) {
      // Error codes: https://firebase.google.com/docs/reference/js/auth#autherrorcodes
      const { name, code } = err; // customData
      const errorType = code || name;
      switch (errorType) {
        case "auth/invalid-email":
          setError((prevError) => ({
            ...prevError,
            email: "Invalid email address.",
          }));
          break;
        case "auth/invalid-credential":
          setError((prevError) => ({
            ...prevError,
            password: "Invalid credentials",
          }));
          break;
        default:
          setError((prevError) => ({
            ...prevError,
            other: `Login error occured: ${err.code}`,
          }));
          console.warn(`Firebase Error occured: ${err}`);
          break;
      }
    } else if (err instanceof AxiosError) {
      // console.log('Axios error', err)
      if (err.response.status === 409) {
        setError((prevError) => ({
          ...prevError,
          email: "Email is already registered.",
        }));
      }
    } 
    else {
      // not Firebase or Axios error
      handleUnknownError();
    }
  }