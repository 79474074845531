import React from "react";
import { AccountBox, Chat, CurrencyExchange, ExitToApp, HelpCenter, Home, Inventory, Login, MonetizationOn, People, Search } from "@mui/icons-material";
import { Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Config from "../../shared/Config";
const { releaseVersion } = Config();

/**
 * @param {object} p
 */
function AppDrawer({ store, currentUser, drawerOpen, setDrawerOpen, setShowLogin, setShowSearch, logOut }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { pathname } = useLocation();
  let claims = {};
  try {
    claims = currentUser?.claims || {}
  } catch (ex) {
    console.warn(ex.message);
  }

  return (
    <Drawer
      variant={fullScreen ? "permanent":"temporary"}
      anchor={'right'}
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <List>
        <ListItemButton style={{ color: '#000' }} component={Link} to={'/'}
          selected={pathname === '/'}
          onClick={() => setDrawerOpen(false)}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton style={{ color: '#000' }} component={Link} to={'/'}
          onClick={() => {
            setShowSearch(true);
            setDrawerOpen(false);
          }}>
          <ListItemIcon>
            <Search />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </ListItemButton>
        {currentUser ?
          <>
            <ListItemButton style={{ color: '#000' }} component={Link} to={'/profile'}
              selected={pathname === '/profile'}
              onClick={() => setDrawerOpen(false)}>
              <ListItemIcon>
                <AccountBox />
              </ListItemIcon>
              <ListItemText primary={`Profile: ${(claims?.email || '').substring(0,11)}..`} />
            </ListItemButton>
            <ListItemButton component={Link}
              to={'/orders'}
              selected={pathname === '/orders'}
              onClick={() => setDrawerOpen(false)}>
              <ListItemIcon>
                <CurrencyExchange />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </ListItemButton>
          </>
          :
          <ListItemButton onClick={() => {
            setShowLogin(true)
            setDrawerOpen(false)
          }}>
            <ListItemIcon>
              <Login />
            </ListItemIcon>
            <ListItemText primary="Log in" />
          </ListItemButton>}
      </List>
      <Divider />
      {claims.sellerStores?.includes(store._id) &&
        <List subheader={<ListSubheader>Seller settings</ListSubheader>}>
          <ListItemButton sx={{ pl: 4 }} component={Link}
            to={'/listings'}
            selected={pathname === '/listings'}
            onClick={() => setDrawerOpen(false)}>
            <ListItemIcon>
              <Inventory />
            </ListItemIcon>
            <ListItemText primary="Listings" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component={Link}
            to={'/sales'}
            selected={pathname === '/sales'}
            onClick={() => setDrawerOpen(false)}>
            <ListItemIcon>
              <MonetizationOn />
            </ListItemIcon>
            <ListItemText primary="Sales" />
          </ListItemButton>
        </List>}
      <List>
        {currentUser &&
          <>
            <ListItemButton style={{ color: '#000' }} component={Link}
              to={'/chat'} onClick={() => setDrawerOpen(false)}>
              <ListItemIcon>
                <Chat />
              </ListItemIcon>
              <ListItemText primary="Chat" />
            </ListItemButton>
            <ListItemButton style={{ color: '#000' }} component={Link}
              to={'/sellers'} onClick={() => setDrawerOpen(false)}>
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListItemText primary="Sellers" />
            </ListItemButton>
            <ListItemButton
              onClick={async () => {
                try {
                  await logOut()
                } catch (error) {
                  console.warn(error)
                }
                setDrawerOpen(false);
              }}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItemButton>
          </>
        }
        <ListItemButton style={{ color: '#000' }} component={Link}
          to={'/page/about'} onClick={() => setDrawerOpen(false)}>
          <ListItemIcon>
            <HelpCenter />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
      </List>
      <Divider />
      <Typography variant={"caption"} sx={{m:3}}>{`v${releaseVersion}`}</Typography>
    </Drawer>
  )
}

export default AppDrawer;