import React from "react";
import Debug from "debug";
import { useAuth } from "../modules/Auth/AuthProvider";
import { useAPI } from "./useAPI";
const debug = Debug("hooks:useUsers");

export function useUsers() {
  const { currentUser } = useAuth();
  // const [userSaved, setUserSaved] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const { providerType } = currentUser
  const anonUser = (providerType === 'anon-user');
  const { accessToken } = currentUser;
  const { data, loading, instance } = useAPI('me', { accessToken });

  React.useEffect(() => {
    if (anonUser) {
      setUser({ fullname: 'Guest User' })
      return;
    }

    if (!loading && Object.keys(data)) {
      // console.log("setUser:", data, Object.keys(data), loading)
      setUser(data);
    }
    if (data) {
      debug("data >>", data)
    }
  }, [data, loading, anonUser]);

  const upsertUser = async (profile) => {
    // profile.updated_ts = new Date();
    instance.put('me', { ...profile })
    // setUserSaved(true);
    setUser(user);
  };

  return {
    user,
    setUser,
    currentUser,
    loading,
    upsertUser
  };
}
